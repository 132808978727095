<template>
  <v-dialog v-model="dialog" :fullscreen="$vuetify.breakpoint.mobile" :width="$vuetify.breakpoint.mobile?'':'50%'" hide-overlay>
    <v-card :dark="dark">
        <v-toolbar color="primary" dark>
            <v-btn icon @click="close">
                <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-toolbar-title>{{lang[getLanguage].REDEEM_FORM}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-title>
              <v-chip color="white" outlined><v-icon class="mr-2">mdi-wallet</v-icon> {{ totalMolaPoint }} {{lang[getLanguage].MY_ACCOUNT_MOLA_POINTS}}</v-chip>
            </v-toolbar-title>
        </v-toolbar>
        <v-container fluid class="px-8 py-8">
          <v-row>
                <v-col col="12">
                    <v-text-field
                      v-model="username"
                      :label="lang[getLanguage].USERNAME"
                      disabled
                      outlined
                      hide-details
                      type="text"
                      dense
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row class="">
                <v-col col="12">
                    <v-text-field
                      v-model="product.title"
                      :label="lang[getLanguage].PRODUCT_NAME"
                      disabled
                      outlined
                      hide-details
                      type="text"
                      dense
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col col="12">
                    <v-text-field
                      v-model="price"
                      :label="lang[getLanguage].PRODUCT_PRICE"
                      disabled
                      outlined
                      hide-details
                      type="text"
                      dense
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row v-if="product.isVariant">
                <v-col col="12">
                    <v-select
                      v-model="variant"
                      :label="'Pilih ' + product.variantDetail?.title"
                      outlined
                      hide-details
                      :items="product.variants"
                      item-value="label"
                      item-text="dropdown_text"
                      :item-disabled="isVariantDisabled"
                      dense
                    ></v-select>
                </v-col>

            </v-row>
            <v-row>
                <v-col col="12">
                    <v-slider
                      :label="lang[getLanguage].QTY"
                      class="align-center"
                      v-model="qty"
                      :max="maxRedeemQty"
                      :min="0"
                      thumb-label
                      hide-details
                      ticks
                    >
                    <template v-slot:append>
                    <v-text-field
                      style="width:150px"
                      v-model="qty"
                      class="mt-0 pt-0"
                      hide-details
                      outlined
                      dense
                      :max="maxRedeemQty"
                      :min="0"
                      suffix="Pcs"
                      single-line
                      type="number"
                      :disabled="maxRedeemQty < 1"
                    ></v-text-field>
                  </template>
                  
                  </v-slider>

                </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="d-flex justify-end">
                Total <span class="ml-2 font-weight-bold">{{ (product.price * qty) + " " }} {{lang[getLanguage].MY_ACCOUNT_MOLA_POINTS}}</span>
              </v-col>
            </v-row>
            <v-row v-if="product.type === 1">
              <v-col cols="12">
                <v-divider></v-divider>
                <span class="ml-md-2 mt-2 mx-auto">{{lang[getLanguage].SINCE_THIS_IS_A_PHYSICAL_ITEM_PLEASE_PROVIDE_THE_SHIPPING_ADDRESS}}</span><br>
                <span class="ml-md-2 mt-2 mx-auto font-weight-bold">{{lang[getLanguage].SHIPPING_DETAILS}} </span>
                    <v-text-field class="ml-md-2 mt-2 mx-auto"
                      v-model="receiverName"
                      :placeholder="lang[getLanguage].RECEIVER_NAME_IF_DIFFERENT_FROM_ACCOUNT_NAME"
                      hide-details
                      outlined
                      dense
                      single-line
                    ></v-text-field>
                    <v-text-field class="ml-md-2 mt-2 mx-auto"
                      v-model="address1"
                      :placeholder="lang[getLanguage].ADDRESS_LINE_1"
                      hide-details
                      outlined
                      dense
                      single-line
                    ></v-text-field>
                    <v-text-field class="ml-md-2 mt-2 mx-auto"
                      v-model="address2"
                      :placeholder="lang[getLanguage].ADDRESS_LINE_2"
                      hide-details
                      outlined
                      dense
                      single-line
                    ></v-text-field>
                    <v-text-field class="ml-md-2 mt-2 mx-auto"
                      v-model="city"
                      :placeholder="lang[getLanguage].CITY"
                      hide-details
                      outlined
                      dense
                      single-line
                    ></v-text-field><v-text-field class="ml-md-2 mt-2 mx-auto"
                      v-model="country"
                      :placeholder="lang[getLanguage].COUNTRY"
                      hide-details
                      outlined
                      dense
                      single-line
                    ></v-text-field>
                    <v-text-field class="ml-md-2 mt-2 mx-auto"
                      v-model="postalCode"
                      :placeholder="lang[getLanguage].POSTAL_CODE"
                      hide-details
                      outlined
                      dense
                      single-line
                    ></v-text-field>
                    <v-text-field class="ml-md-2 mt-2 mx-auto"
                      v-model="phoneNumber"
                      type="text"
                      :placeholder="lang[getLanguage].PHONE_NUMBER"
                      hide-details
                      outlined
                      dense
                      single-line
                      @keypress="filter(event)"
                    ></v-text-field>
                <v-divider></v-divider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-btn block color="primary" class="mx-0" style="border-radius: 5px" @click.stop="redeemNow" :disabled="isDisabledButton()">
                    <v-progress-circular v-if="isLoading"
                      indeterminate
                      color="white"
                    ></v-progress-circular>
                    <span v-else>
                      {{lang[getLanguage].SUBMIT_MY_ORDER}}
                    </span>
                </v-btn>
              </v-col>
            </v-row>
        </v-container>
    </v-card>
    <v-snackbar :dark="dark"
      v-model="snackbar"
      :timeout="3000"
      outlined
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

  </v-dialog>
</template>

<script>
import { db } from '../../main';
import MolaMfpAPI from '../../clients/MolaMfpAPI';
export default {
  props: {
    product: Object,
    isOpen: Boolean,
    dark: Boolean,
  },
  data() {
    return {
      selfMolaPoints: 100,
      snackbar: false,
      text: '',
      qty: 0,
      receiverName:'',
      address1: '',
      address2: '',
      city: '',
      country: '',
      postalCode: '',
      phoneNumber: '',
      isLoading: false,
      buttonDisable: false,
      variant: '',
    };
  },
  computed:{
    dialog: {
      get() {
        return this.isOpen;
      },
      set(newValue) {
        this.$emit('close', newValue);
      },
    },
    price(){
      return this.product.price + ' ' + this.lang[this.getLanguage].MY_ACCOUNT_MOLA_POINTS
    },
    user() {
      return this.$store.getters.user;
    },
    lang(){
      return this.$store.state.lang;
    },
    getLanguage(){
      return this.$store.state.current_language
    },
    userDetail() {
      return this.$store.state.user;
    },
    username(){
      return ([undefined,null].includes(this.user) ? 'Guest User' : this.user.name?.toUpperCase()) ?? this.getUser.name?.toUpperCase();
    },
    getUser(){
      return this.$store.state.user;
    },
    totalMolaPoint(){
      return this.$store.state.user.mola_points;
    },
    maxRedeemQty(){
      if(![null, undefined].includes(this.product.variantDetail) && ![null, undefined, ''].includes(this.variant)){
        let selectedVariant = this.product.variants.find(item => item.label === this.variant);
        return Math.floor(this.totalMolaPoint/this.product.price) < (selectedVariant.initial_qty - selectedVariant.redeemed) ? Math.floor(this.totalMolaPoint/this.product.price) : (selectedVariant.initial_qty - selectedVariant.redeemed)
      } else {
        return Math.floor(this.totalMolaPoint/this.product.price) < (this.product.stock - this.product.redeemed) ? Math.floor(this.totalMolaPoint/this.product.price) : (this.product.stock - this.product.redeemed)
      }
    },
  },
  created(){
    this.$store.dispatch("getUserPoints");
    console.log(this.userDetail);
    console.log(this.totalMolaPoint);
    if(this.product.type === 1){
      if(Array.isArray(this.userDetail.address) && this.userDetail.address.length > 0){
        this.address1 = this.userDetail.address[0].address_1;
        this.address2 = this.userDetail.address[0].address_2;
        this.city = this.userDetail.address[0].city;
        this.country = this.userDetail.address[0].country ?? '';
        this.postalCode = this.userDetail.address[0].postal_code;
        this.phoneNumber = this.userDetail.address[0].phone;
        this.receiverName = this.userDetail.address[0].name;
      }
    }

    if(![null, undefined].includes(this.product.variantDetail)){
      this.product.variants.map(item => {
        console.log(item)
        if(item.initial_qty - item.redeemed === 0){
          item.dropdown_text = item.dropdown + ' - Stok Habis';
        } else 
        {
          item.dropdown_text = item.dropdown
        }
      })
    }
  },
  watch:{
    snackbar(newValue){
      console.log("snackbar", newValue);
      if(!newValue){
        this.dialog = false;
      }
    }
  },
  methods: {
    filter (evt) {
      evt = (evt) ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();
      
      if (!/^[-+]?[0-9-+]*\.?[0-9-+]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    redeemNow() {
      this.isLoading = true;
      console.log("redeem")
      let address = null;
      const totalDeductedPoint = this.product.price * this.qty;
      if(this.qty === 0) {
        this.text = 'Jumlah barang yang dipesan tidak boleh 0';
        this.snackbar = true;
        return;
      }

      const orderPostKey = db.collection("order").doc();
      const initialMolaPoint = this.totalMolaPoint;
      const finalMolaPoint = this.totalMolaPoint - totalDeductedPoint;
      if(this.product.type === 1){
        address = {
          name: this.receiverName,
          address_1: this.address1,
          address_2: this.address2 ?? "",
          city: this.city,
          country: this.country,
          postal_code: this.postalCode,
          phone: this.phoneNumber
        }
      }
      this.$store.dispatch("redeemNow", {
        order_id: orderPostKey.id,
        uid: this.user.id,
        item: this.product,
        qty: this.qty,
        mola_point_initial: initialMolaPoint,
        mola_point_final: finalMolaPoint,
        price: this.product.price,
        total: totalDeductedPoint,
        variant: this.variant,
        external_uid: this.userDetail.mfp_fight_uid,
        username: this.userDetail.name,
        email: this.userDetail.email,
        address: address,
        language: 'id' //this.getLanguage
      }).then(() => {
        this.text = this.lang[this.getLanguage].ORDER_HAS_BEEN_SUBMITTED_SUCCESSFULLY;
        this.snackbar = true;
        // Send email from here
        var payload =  {
          uid: this.user.id,
          item: this.product,
          qty: this.qty,
          variant: this.variant,
          mola_point_initial: initialMolaPoint,
          mola_point_final: finalMolaPoint,
          total: totalDeductedPoint,
          username: this.userDetail.name,
          email: this.userDetail.email,
          order_id: orderPostKey.id,
          language: 'id', //this.getLanguage
        }
        console.log(payload)
        Promise.resolve(MolaMfpAPI.sendOrderEmail(payload))
        .then(result => {
          console.log(result)
          // Handle Result
        })
        .catch(err => {
          // this.loading = false
          console.log('Error send email', err)
          // show friendly error in user screen
        })

      }).catch(err=>{
        this.text=err.message;
        this.snackbar=true;
      }).finally(() => {
        this.$store.dispatch("getUserPoints");
        this.isLoading = false;
        this.buttonDisable = true;
      })
    },
    close(){
      this.$emit("close", false);
    },
    isDisabledButton(){
      if(this.qty === 0) return true;
      if(this.product.type === 1){
        if([undefined, null, ''].includes(this.receiverName)) return true;
        if([undefined, null, ''].includes(this.address1)) return true;
        if([undefined, null, ''].includes(this.city)) return true;
        if([undefined, null, ''].includes(this.country)) return true;
        if([undefined, null, ''].includes(this.postalCode)) return true;
        if([undefined, null, ''].includes(this.phoneNumber)) return true;
      }

      if(this.product.isVariant){
        if([undefined, null, ''].includes(this.variant)) return true;
      }

      if(this.isLoading) return true;
      return this.buttonDisable;
    },
    isVariantDisabled(item) {
      return (+item.initial_qty) - (+item.redeemed) === 0;
    },
  },
};
</script>