import { db } from '../main';
import firebase from 'firebase/app';
import MolaMfpAPI from '../clients/MolaMfpAPI';

export default {
  state: {},
  mutations: {
    setState(state, payload) {
      var keys = Object.keys(payload);
      keys.forEach((key) => {
        state[key] = payload[key];
      });
    },
    setStateLocalStorage(state, payload) {
      var keys = Object.keys(payload);
      keys.forEach((key) => {
        state[key] = payload[key];
        localStorage.setItem(
          `${state[key]}-mola-catalog-staging`,
          JSON.stringify(payload)
        );
      });
    },
  },
  actions: {
    async redeemNow({ commit, rootState, dispatch }, payload) {
        console.log(payload);
        let obj = {
          order_id: payload.order_id,
          uid: payload.uid,
          item: payload.item,
          variant: payload.variant ?? '',
          qty: payload.qty,
          address: payload.address,
          total: payload.total,
          language: 'id', //payload.language,
          username: payload.username,
          email: payload.email,
          mola_point_initial: payload.mola_point_initial,
          mola_point_final: payload.mola_point_final,
          external_uid: payload.external_uid,
          platform: ['MFP-FIGHT'],
          order_date: new Date().getTime(),
          status: 0,
        };
        if (payload.item.type !== 1) {
          delete obj.address;
        }
        await db.collection('order').doc(payload.order_id).set(obj);
        if (payload.item.type === 1) {
          if (
            !Array.isArray(rootState.user.address) ||
            rootState.user.address.length === 0
          ) {
            await db
              .collection('users')
              .doc(payload.uid)
              .update(
                {
                  address: [payload.address],
                },
                { merge: true }
              );
          } else {
            if (
              !rootState.user.address
                .map((item) => item.address_1)
                .includes(payload.address.address_1)
            ) {
              const userRef = db.collection('users').doc(payload.uid);

              await userRef.update({
                address: firebase.firestore.FieldValue.arrayUnion(
                  payload.address
                ),
              });
            }
          }
        }
        commit("setState", { 
          tx_status: 'Success'
        })
        if([null, undefined, ''].includes(payload.variant)){
          await db.collection('catalog').doc(payload.item.id).update(
            {
              redeemed: firebase.firestore.FieldValue.increment(payload.qty * 1),
              // item_quantity: firebase.firestore.FieldValue.increment(payload.qty * -1),
              item_quantity_left: firebase.firestore.FieldValue.increment(payload.qty * -1),
            }
          );

          dispatch(
            'addRedeemed',
            {
              redeemed: payload.qty,
              item_id: payload.item.id,
            },
            { root: true }
          );
        }

        if(![null, undefined, ''].includes(payload.variant)){
          const catalog = await db.collection('catalog').doc(payload.item.id).get()
          let variants = catalog.data().variants;
          variants = variants.map(variant => {
            if (variant.label === payload.variant) {
              return { ...variant, redeemed: +((+variant.redeemed) +  (+payload.qty)) }; // Modify as needed
            }
            return variant;
          });
          console.log(variants);
          await db.collection('catalog').doc(payload.item.id).update(
            { 
              variants: variants,
              redeemed: firebase.firestore.FieldValue.increment(payload.qty * 1),
              item_quantity_left: firebase.firestore.FieldValue.increment(payload.qty * -1),
            }
          );

          dispatch(
            'addRedeemedVariant',
            {
              item_id: payload.item.id,
              variants: payload.variants
            },
            { root: true }
          );
        }
                
        await MolaMfpAPI.deductPointInMFP({
          order_id: payload.order_id,
          item_id: payload.item.id,
          qty: payload.qty,
          item_name: payload.item.title,
          price: payload.item.price,
          points: -(payload.total),
          uid: payload.external_uid,
        });
        // dispatch('setUserPointLocal', payload.total);
    },
  },
  getters: {},
};
