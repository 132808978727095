<template>
  <v-app>
    <v-navigation-drawer 
      v-model="drawer"
      app
      :dark="dark"
      :color="dark ? '#202020' : 'grey lighten-3'"
      width="280"
    >
      <v-btn icon @click.stop="drawer = !drawer" style="margin: 10px">
        <v-icon>mdi-window-close</v-icon>
      </v-btn>

      <v-layout column align-center pl-2 pr-2 style="margin-top: 10px;margin-bottom: 10px">
        <v-img
          v-if="!dark"
          style="cursor: pointer"
          @click="routerGo('/')"
          v-scroll-to="'#home'"
          :src="logoSrcList"
          max-width="200"
          max-height="70"
        ></v-img>
        <v-img
          v-if="dark"
          style="cursor: pointer"
          @click="routerGo('/')"
          v-scroll-to="'#home'"
          :src="logoSrcListDark"
          max-width="200"
          max-height="70"
        ></v-img>
      </v-layout>

      <v-list-item style="margin-top: -20px">
        <v-list-item-content>
          <v-list-item-title class="text-h5 text-center pt-4">
            Mola Digital - Catalog
          </v-list-item-title>
          <v-list-item-subtitle> </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider v-if="['USER', 'ADMIN'].includes(userRole)"></v-divider>
        <v-layout style="margin:10px" v-if="['USER',  'ADMIN'].includes(userRole)">
          <v-spacer></v-spacer>
            <v-icon color="secondary" >mdi-wallet</v-icon>
            <div :class="dark ? 'pl-2 white--text' : 'pl-2'">
              {{ totalMolaPoint }} {{ lang[getLanguage].MY_ACCOUNT_MOLA_POINTS }}
            </div>
          <v-spacer></v-spacer>
        </v-layout>
      <v-divider v-if="['USER', 'ADMIN'].includes(userRole)"></v-divider>

      <!--  <v-divider></v-divider> -->

      <!-- Admin Console Menu  -->
      <v-list v-if="['ADMIN', 'ADMIN-CATALOG', 'ADMIN-ORDER'].includes(userRole)" 
        dense nav class="ml-2">
        <v-list-item v-show="['ADMIN', 'ADMIN-CATALOG', 'ADMIN-ORDER'].includes(userRole)" link @click.stop="routerGo('/admin')">
          <v-list-item-icon>
            <v-icon>mdi-view-dashboard</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ lang[getLanguage].ADMIN_DASHBOARD }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

         <v-list-group v-show="['ADMIN', 'ADMIN-CATALOG'].includes(userRole)" prepend-icon="mdi-vector-arrange-below" no-action>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{ lang[getLanguage].MANAGE_CATALOG }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item link @click.stop="routerGo('/admin/catalog')">
            <v-list-item-icon>
              <v-icon>mdi-view-list</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ lang[getLanguage].CATALOG }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link @click.stop="routerGo('/admin/category')">
            <v-list-item-icon>
              <v-icon>mdi-shape-plus-outline</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ lang[getLanguage].CATEGORY }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link @click.stop="routerGo('/admin/tracking')">
            <v-list-item-icon>
              <v-icon>mdi-chart-timeline</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ lang[getLanguage].TRACKING_DASHBOARD }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

        </v-list-group>
        <v-list-group v-show="['ADMIN', 'ADMIN-ORDER'].includes(userRole)" prepend-icon="mdi-storefront-outline" no-action>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{ lang[getLanguage].MANAGE_ORDER }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item link @click.stop="routerGo('/admin/order')">
            <v-list-item-icon>
              <v-icon>mdi-receipt-text-outline</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ lang[getLanguage].ORDER_MANAGEMENT }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

        </v-list-group>

        <v-list-group v-show="['ADMIN'].includes(userRole)" prepend-icon="mdi-account-cog-outline" no-action>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{ lang[getLanguage].ADMIN_TASK }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item link @click.stop="routerGo('/admin/favorites')">
            <v-list-item-icon>
              <v-icon>mdi-format-list-numbered</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ lang[getLanguage].SIDE_MENU_FAVORITES }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

        </v-list-group>

        <v-list-item
          link
          @click="backToMfp('https://molafightclub.com')"
        >
          <v-list-item-icon>
            <v-icon>mdi-arrow-u-left-top</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ lang[getLanguage].BACK_TO_MFP }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-layout column align-center pa-4 v-if="isUserLogin">
          <v-btn small :dark="dark" outlined @click="signOut" style="width:150px"
            ><v-icon small left>mdi-logout-variant</v-icon>{{ lang[getLanguage].SIGN_OUT }}</v-btn
          >
        </v-layout>
        

        <!-- <v-list-group prepend-icon="mdi-vector-link" no-action>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{ lang[getLanguage].POINTS_AND_TOKEN_MANAGEMENT }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item link @click.stop="routerGo('/adm')">
            <v-list-item-icon>
              <v-icon>mdi-circle-multiple-outline</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ lang[getLanguage].POINTS_MANAGEMENT }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link @click.stop="routerGo('/admresults')">
            <v-list-item-icon>
              <v-icon>mdi-swap-horizontal-bold</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ lang[getLanguage].SWAP_REQUEST }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

        </v-list-group> -->

      </v-list>

      <v-list
        v-if="['USER', 'PUBLIC'].includes(userRole)"
        dense
        nav
        class="ml-2"
      >
        <v-list-item link @click.stop="routerGo('/')" v-scroll-to="'#home'">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ lang[getLanguage].HOME }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          @click="backToMfp('https://molafightclub.com')"
        >
          <v-list-item-icon>
            <v-icon>mdi-arrow-u-left-top</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ lang[getLanguage].BACK_TO_MFP }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="isUserLogin"
          link
          v-scroll-to="'#accounthome'"
          @click.stop="routerGo('/myaccount')"
        >
          <v-list-item-icon>
            <v-icon>mdi-account-circle</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ lang[getLanguage].MY_ACCOUNT }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="isUserLogin"
          link
          v-scroll-to="'#myitems'"
          @click.stop="routerGo('/myitems')"
        >
          <v-list-item-icon>
            <v-icon>mdi-format-list-bulleted-type</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ lang[getLanguage].MY_ITEMS }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-layout column align-center pa-4 v-if="isUserLogin">
          <v-btn small :dark="dark" outlined @click="signOut" style="width:150px"
            ><v-icon small left>mdi-logout-variant</v-icon>{{ lang[getLanguage].SIGN_OUT }}</v-btn
          >
        </v-layout>
        <!-- 
        <div v-if="isMobileDevice">
          <v-divider></v-divider>

          <v-layout pa-2>
            <v-spacer></v-spacer>
            <v-btn
              icon
              @click.stop="changeThemeMenu(false)"
              :color="dark ? '#E1F5FE' : '#BDBDBD'"
            >
              <v-icon>mdi-white-balance-sunny</v-icon>
            </v-btn>
            <v-btn
              icon
              @click.stop="changeThemeMenu(true)"
              :color="dark ? '#000' : '#212121'"
            >
              <v-icon>mdi-moon-waxing-crescent</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
          </v-layout>
        </div> -->
        
      </v-list>
<!-- 
      <template v-slot:append v-if="!isMobileDevice">
        <div>
          <v-divider></v-divider>

          <v-layout pa-2>
            <v-spacer></v-spacer>
            <v-btn
              icon
              @click.stop="changeThemeMenu(false)"
              :color="dark ? '#E1F5FE' : '#BDBDBD'"
            >
              <v-icon>mdi-white-balance-sunny</v-icon>
            </v-btn>
            <v-btn
              icon
              @click.stop="changeThemeMenu(true)"
              :color="dark ? '#000' : '#212121'"
            >
              <v-icon>mdi-moon-waxing-crescent</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
          </v-layout>
        </div>
      </template> -->
      <v-divider class="my-2"></v-divider>
      <div class="d-flex mb-4 justify-center" v-if="!['USER', 'PUBLIC'].includes(userRole)">
        <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                style="
                  text-transform: none !important;
                  background-color: unset;
                  box-shadow: none;
                "
                v-bind="attrs"
                v-on="on"
              > 
                <v-icon small class="mr-md-2" v-if="getLanguage === 'id'">$vuetify.icons.indonesia</v-icon>
                <v-icon small class="mr-md-2" v-if="getLanguage === 'en'">$vuetify.icons.english</v-icon>
                <v-icon small class="mr-md-2" v-if="getLanguage === 'it'">$vuetify.icons.italy</v-icon>
                <span v-if="!isMobileDevice">{{ getLanguage.toUpperCase() }}</span>
                <v-icon right> mdi-chevron-down </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item>
                <v-list-item-title
                  style="cursor: pointer"
                  @click="changeLanguage('id')"
                  ><v-icon small class="mr-2">$vuetify.icons.indonesia</v-icon>BAHASA</v-list-item-title
                >
              </v-list-item>
              <v-list-item>
                <v-list-item-title
                  style="cursor: pointer"
                  @click="changeLanguage('en')"
                  ><v-icon small class="mr-2">$vuetify.icons.english</v-icon>ENGLISH</v-list-item-title
                >
              </v-list-item>
              <!-- <v-list-item>
                <v-list-item-title
                  style="cursor: pointer"
                  @click="changeLanguage('it')"
                  ><v-icon small class="mr-2">$vuetify.icons.italy</v-icon>ITALIAN</v-list-item-title
                >
              </v-list-item> -->
            </v-list>
        </v-menu>
      </div>
    </v-navigation-drawer>

    <v-app-bar
      :height="isMobileDevice ? 60 : 80"
      app
      :dark="dark"
      :class="dark ? '#202020' : 'grey lighten-3'"
    >
    <!-- v-if="isMobileDevice || !['USER', 'PUBLIC'].includes(userRole)" -->
      <v-app-bar-nav-icon @click="drawer = !drawer" ></v-app-bar-nav-icon>
      <v-spacer v-if="isMobileDevice"></v-spacer>
      <div class="d-flex align-center">
        <!-- <v-toolbar-title v-show="!drawer && !isMobileDevice"
          >Mola Digital - Catalog</v-toolbar-title
        > -->
        <!-- <v-img
        :src="
          dark
            ? '/img/logos/superlive_x_mola_horizontal.webp'
            : '/img/logos/superlive_x_mola_horizontal.webp'
        "
        @click="routerGo('/')"
        :style="`cursor: pointer; max-height: 65px; max-width: ${isMobileDevice ? '240px' : '320px'}`"
      ></v-img> -->
      </div>


      
      <!-- <v-img
        v-if="!isMobileDevice"
        src="/img/logos/ufc_logo.png"
        style="max-height: 35px; max-width: 100px"
      ></v-img> -->

      <v-spacer></v-spacer>

      <!-- <v-btn
        target="_blank"
        text
        class="px-1 mx-1"
        @click.stop="showLoginPanel(true)"
        v-show="!isUserLogin"
      >
        <v-icon :small="isMobileDevice">mdi-login</v-icon>
        <span class="mr-2 ml-2">{{ lang[getLanguage].LOGIN }} </span>
      </v-btn> -->
      <v-btn v-show="isUserLogin" text @click.stop="routerGo('/myaccount')" class="px-1 mx-1" v-scroll-to="'#accounthome'">
        <v-icon large v-if="isUserLogin && ['USER', 'PUBLIC'].includes(userRole)" color="secondary"
          >mdi-account-circle</v-icon
        >
        <v-icon large v-if="isUserLogin && ['ADMIN', 'ADMIN-NEWS'].includes(userRole)" color="secondary"
          >mdi-account-key-outline</v-icon
        >
        <v-row :class="isMobileDevice ? 'mr-md-2 ml-md-2 mr-1 ml-1 caption pa-0' : 'mr-md-2 ml-md-2 mr-1 ml-1 pa-0'" v-if="!isMobileDevice">
          <v-col cols="12" class="pa-0 d-flex flex-column align-start">
            Halo, {{ getUser.name }}
            <!-- <v-chip x-small color="#C40621" class="white--text">
              {{ lang[getLanguage].MY_ACCOUNT_MOLA_POINTS }} : {{ totalMolaPoint }} {{ lang[getLanguage].POINTS }}
            </v-chip> -->
            <span style="font-size: 7pt;">
              {{ lang[getLanguage].MY_ACCOUNT_MOLA_POINTS }} : {{ totalMolaPoint }} {{ lang[getLanguage].POINTS }}
            </span>
          </v-col>
        </v-row>
      </v-btn>
      <!-- <v-menu offset-y v-if="!['USER', 'PUBLIC'].includes(userRole)">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              variant="text"
              style="
                text-transform: none !important;
                background-color: unset;
                box-shadow: none;
              "
              v-bind="attrs"
              v-on="on"
            > 
              <v-icon small class="mr-md-2" v-if="getLanguage === 'id'">$vuetify.icons.indonesia</v-icon>
              <v-icon small class="mr-md-2" v-if="getLanguage === 'en'">$vuetify.icons.english</v-icon>
              <v-icon small class="mr-md-2" v-if="getLanguage === 'it'">$vuetify.icons.italy</v-icon>
              <span v-if="!isMobileDevice">{{ getLanguage.toUpperCase() }}</span>
              <v-icon right> mdi-chevron-down </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-list-item-title
                style="cursor: pointer"
                @click="changeLanguage('id')"
                ><v-icon small class="mr-2">$vuetify.icons.indonesia</v-icon>BAHASA</v-list-item-title
              >
            </v-list-item>
            <v-list-item>
              <v-list-item-title
                style="cursor: pointer"
                @click="changeLanguage('en')"
                ><v-icon small class="mr-2">$vuetify.icons.english</v-icon>ENGLISH</v-list-item-title
              >
            </v-list-item>
            <v-list-item>
              <v-list-item-title
                style="cursor: pointer"
                @click="changeLanguage('it')"
                ><v-icon small class="mr-2">$vuetify.icons.italy</v-icon>ITALIAN</v-list-item-title
              >
            </v-list-item>
          </v-list>
      </v-menu> -->
    </v-app-bar>

    <div id="hometoolbar" v-if="false">
      <v-toolbar
        v-if="
          $router.currentRoute.path !== '/admin/login' &&
          !isMobileDevice
        "
        :style="isMobileDevice ? 'margin-top: 60px' : 'margin-top: 80px'"
        max-height="60"
        color="#f8df8e"
      >
        <v-layout v-if="!drawer && !isMobileDevice && !loading">
          <v-btn text @click.stop="routerGo('/')" :style="window.width < 1250 ? 'font-size: 0.6rem;padding-left:7px;padding-right:1px' : 'font-size: 0.9rem;'">
            {{ lang[getLanguage].HOME }}
          </v-btn>
          <span style="margin-top:5px">|</span>
          <v-btn text @click.stop="backToMfp('https://molafightclub.com')" :style="window.width < 1250 ? 'font-size: 0.6rem;padding-left:7px;padding-right:7px' : 'font-size: 0.9rem;'">
            {{ lang[getLanguage].BACK_TO_MFP  }}
          </v-btn>
          <span style="margin-top: 5px" v-if="isUserLogin">|</span>
          <v-btn text v-if="isUserLogin" @click.stop="routerGo('/myaccount')" :style="window.width < 1250 ? 'font-size: 0.6rem;padding-left:7px;padding-right:7px' : 'font-size: 0.9rem;'">
            {{ lang[getLanguage].MY_ACCOUNT  }}
          </v-btn>
          <span style="margin-top: 5px" v-if="isUserLogin">|</span>
          <v-btn text v-if="isUserLogin" @click.stop="routerGo('/myitems')" :style="window.width < 1250 ? 'font-size: 0.6rem;padding-left:7px;padding-right:7px' : 'font-size: 0.9rem;'">
            {{ lang[getLanguage].MY_ITEMS  }}
          </v-btn>
          <span style="margin-top: 5px" v-if="isUserLogin">|</span>
          <v-btn @click="signOut" text v-if="isUserLogin">{{ lang[getLanguage].SIGN_OUT }}</v-btn>
        </v-layout>
        <v-layout v-else-if="isMobileDevice">
          <v-spacer></v-spacer>
          <!-- <v-img
            src="/img/logos/mola_logo_dark.png"
            style="max-height: 35px; max-width: 110px"
          ></v-img> -->
          <!-- <v-img
            src="/img/logos/ufc_logo.png"
            style="max-height: 35px; max-width: 100px"
          ></v-img> -->
        </v-layout>
        <v-spacer></v-spacer>
        <!-- <v-btn
          v-if="window.width > 900"
          style="margin-left: 15px"
          dark
          color="#db0019"
          rounded
          :small="window.width >= 1250"
          :x-small="window.width < 1250"
          :style="window.width < 1250 ? 'font-size: 0.6rem;' : 'font-size: 0.9rem;'"
          v-scroll-to="'#hometoolbar'"
          @click.stop="backToMfp('https://molafightclub.com/schedule')"
          >{{ lang[getLanguage].HOME_BUTTON }} 
          <v-icon>mdi-arrow-right-thin </v-icon>
        </v-btn> -->
      </v-toolbar>
    </div>
    <v-main
    :style="`padding-top: ${isMobileDevice ? '60px' : $router.currentRoute.path === '/admin/login' ? '80px' : ['USER', 'PUBLIC'].includes(userRole) ? '80px' : '80px'}`"     
    >
      <LoginWindow
        class="mt-3"
        :isOpen="isShowLogin"
        @change="showLoginPanel"
        @setUser="setUserFromLoginPanel"
        :dark="dark"
        :isMobileDevice="isMobileDevice"
        v-if="isShowLogin"
      ></LoginWindow>
      <Contact
        class="mt-3"
        :isOpen="isShowContact"
        @change="showContactPanel"
        :dark="dark"
        :isMobileDevice="isMobileDevice"
      ></Contact>
      <router-view
        :isMobileDevice="isMobileDevice"
        :dark="dark"
        :drawer="drawer"
        v-on:loginClicked="showLoginPanel(true)"
        v-on:fileDialogClicked="openFileDialog"
        :windowWidth="window.width"
        :windowHeight="window.height"
      />
      <Footer :isMobileDevice="isMobileDevice"
        @contact="showContactPanel"
        @fileDialogClicked="openFileDialog"
        :drawer="drawer"
        :dark="dark"
        :windowWidth="window.width"
        :windowHeight="window.height">
      </Footer>
    </v-main>
    
    <v-dialog
      v-model="dialogFilePreview" :fullscreen="isMobileDevice"
      :width="isMobileDevice ? '100%' : '800'"
      height="auto"
    >
      <v-card>
        <v-layout>
          <v-spacer></v-spacer>
          <v-btn color="grey" @click="dialogFilePreview = false" icon small>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-layout>
        <v-layout column align-center>
          <iframe
            :src="fileName"
            :width="isMobileDevice ? '100%' : '800'"
            :height="isMobileDevice ? termsAndPrivacyheight : 700"
          >
          </iframe>
          <p class="mt-3"></p>
        </v-layout>
      </v-card>
    </v-dialog>

    <v-snackbar :timeout="4000" :bottom="'bottom'" v-model="snackbar">
      <v-icon color="green">mdi-check-circle-outline</v-icon>
      <span style="margin-left:10px">{{ snackbarText }}</span>
            <v-btn text color="#388E3C" @click.native="snackbar = false">{{ lang[getLanguage].CLOSE }}</v-btn>
    </v-snackbar>

    <v-snackbar :timeout="-1" :bottom="'bottom'" v-model="snackbarError">
      <v-icon color="amber">mdi-alert-outline</v-icon>
      <span style="margin-left:10px">{{ snackbarText }}</span>
      <v-spacer></v-spacer>
    </v-snackbar>
    
  </v-app>
</template>

<script>
import Contact from './components/shared/Contact.vue';
import { isMobile } from 'mobile-device-detect';
import Footer from './components/shared/Footer.vue';
import { db } from '@/main';
// import MolaMfpAPI from './clients/MolaMfpAPI';
// import { scroller } from 'vue-scrollto/src/scrollTo';
export default {
  name: 'App',
  props: {},
  data: () => ({
    drawer: false,
    dark: true,
    isMobileDevice: false,
    window: {
      width: 0,
      height: 0,
    },
    isShowLogin: false,
    name: '',
    snackbarText: "",
    snackbarColor: "green",
    snackbarTimeout: 2000,
    snackbar: false,
    snackbarError: false,
    isOnline: true,
    isShowContact: false,
    liveLeaderboard: false,
    limitLiveLeaderboard: 10,
    dialogFilePreview: false,
    fileName: '',//'http://docs.google.com/gview?url=',
    termsAndPrivacyheight: 22000
  }),
  components: {
    LoginWindow: () => import('./components/login/LoginWindow.vue'),
    Contact,
    Footer
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('scroll', this.handleScroll);
  },
  created() {
    console.log("Router", this.$route.name)
    this.$store.dispatch("getCategories");
    // console.log("AMPLIFY")
    // MolaMfpAPI.getRegion().then((resp) => {
    //   const region = resp.data?.continentCode ?? 'AS'
    //   console.log('region', region);
    //   this.$store.commit('setState', {
    //     region: region,
    //   });
    // })
    
    if (this.user !== null) {
      this.$store.dispatch('checkSession')
      this.name = this.user.name;
      this.$store.dispatch('getRole');
      this.$store.dispatch('getUserDetail');
      console.log('this.user.dark created')
      console.log(this.getUser)
      // setTimeout(() => {
      // }, 3500);
      // this.$store.dispatch('getMolaSubscription');
      // this.$store.dispatch('getUserRecap');
      this.$store.dispatch('getMolaPointsLedger', {uid: this.mfpId});
      setTimeout(() => {
        this.dark = this.getUser.dark
      }, 1000);
    }
    if (this.role === 1 && ['Home', 'Authorise'].includes(this.$route.name)) {
      this.$router.push('/admin');
    }
    this.isMobileDevice = isMobile;
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    this.logoSrcList = '/img/logos/superlive_logo.png';
    this.logoSrcListDark = '/img/logos/superlive_logo.png';
  },
  computed: {
    lang(){
      return this.$store.state.lang;
    },
    getLanguage(){
      return this.$store.state.current_language
    },
    isUserLogin() {
      return this.user !== null;
    },
    user() {
      return this.$store.getters.user;
    },
    getUser() {
      return this.$store.state.user;
    },
    displayName() {
      return this.$store.getters.user === null ? '' : this.user.name;
    },
    role() {
      return this.$store.getters.role;
    },
    userRole() {
      switch (this.role) {
        case 1:
          return 'ADMIN';
        case 2:
          return 'ADMIN-ORDER';
        case 3:
          return 'ADMIN-CATALOG';
        case 10:
          return 'USER';
        default:
          return 'PUBLIC';
      }
    },
    totalMolaPoint(){
      return this.$store.state.user.mola_points;
    },
    mfpId(){
      return this.$store.state.user.mfp_fight_uid;
    },
    acc_level(){
      return this.$store.state.user.acc_level
    }
  },
  watch: {
    user(value) {
      if (value !== null && value !== undefined) {
        this.name = value.name;
        this.$store.dispatch('getRole');
        this.$store.dispatch('getUserDetail');
        console.log('this.user.dark watcher')
        console.log(this.getUser)
        // this.$store.dispatch('getUserRecap');
        // this.$store.dispatch('getMolaSub
        this.$store.dispatch('getMolaPointsLedger', {uid: this.mfpId});
        // this.dark = this.getUser.dark
        setTimeout(() => {
          this.dark = this.getUser.dark
        }, 1000);
      }
    },
    mfpId(value){
      if(![undefined,null,''].includes(value)){
        this.$store.dispatch("getUserPoints");
      }
    },
    acc_level(v){
      if(v === 1 && ['Home', 'Authorise'].includes(this.$route.name)){
        this.$router.push('/admin');
      }
    },
    // role(value){
    //   if(value === 1){
    //     this.$router.push('/admin');
    //   }
    // },
    displayName(value) {
      console.log(value);
      this.name = value;
    },
  },
  mounted() {
    console.log("mounted");
    this.connection();
  },
  methods: {
    routerGo(route) {
      /* if (this.isMobileDevice) {
        console.log("this.drawer = false");
        setTimeout(() => {
          this.drawer = false;
        }, 500);
      } */
      // console.log(this.$router.currentRoute.path)
      console.log(route)
      if(route === '/'){
        this.$store.commit('setIsReload', {isReload: true});
      }
      if(this.isMobileDevice){
        this.drawer = false;
      }
      if (this.$router.currentRoute.path !== route) {
        this.$router.push(route);
      }
    },
    makeName (name) {
      // console.log('name.indexOf(',') ===  -1')
      // console.log(name.indexOf(',') ===  -1)
      if (name.indexOf(',') ===  -1) return name
      name.replace(' ', '')
      // console.log(name.replace(' ', '').split(',')[1] + ' ' + name.split(',')[0])
      return name.replace(' ', '').split(',')[1] + ' ' + name.split(',')[0]
    },
    changeThemeMenu(dark) {
      this.dark = dark;
      this.$store.commit('SetTheme', {
        dark: this.dark,
      });
      // Update firebase when logged in
      let obj = {
        dark: this.dark,
      };
      if (this.isUserLogin) {
        db.collection('users')
        .doc(this.user.id)
        .update(obj)
        .then(() => {
          console.log('User Account in bucket updated');
          // Snackbar That confirms
        })
        .catch((error) => {
          console.log(error);
        });
      }
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
      // console.log(this.window.width)
      // this.window.width > 770 ? this.drawer = false : this.drawer = true
    },
    handlePredict() {
      if (!this.user) {
        this.showLoginPanel(true);
      } else {
        this.$router.push('/schedule');
      }
    },
    showLoginPanel(value) {
      this.isShowLogin = value;
    },
    showContactPanel(value) {
      console.log(value);
      this.isShowContact = value;
    },
    setUserFromLoginPanel(value) {
      this.user = value;
    },
    logout() {
      this.$store.dispatch('logout');
    },
    backToMfp(link){
      window.open(link,"_self");
    },
    connection() {
      this.$bus.$on("connection", (online) => {
        if(online){
          this.snackbarError = false;
          this.snackbar = true;
          this.snackbarText = "You are online";
        }else{
          this.snackbar = false;
          this.snackbarError = true;
          this.snackbarText = "You are offline";
        }
      });
    },
    changeLanguage(lang){
      this.$store.commit('SetCurrentLanguage', lang)

      // Update firebase when logged in
      let obj = {
        language: lang,
      };
      if (this.isUserLogin) {
        db.collection('users')
        .doc(this.user.id)
        .update(obj)
        .then(() => {
          console.log('User Account in bucket updated');
          this.$store.commit('setUserState', obj)
          // Snackbar That confirms
        })
        .catch((error) => {
          console.log(error);
        });
      }
    },
    openFileDialog(type){
      let fileName = ''

      if (process.env.VUE_APP_ENVIRONMENT === "production") {
        console.log("PRODUCTION ENVIRONMENT");
        fileName = process.env.VUE_APP_CATALOG_APP;
      }
      else if (process.env.VUE_APP_ENVIRONMENT === "staging") {
        console.log("TEST ENVIRONMENT");
        fileName = process.env.VUE_APP_CATALOG_APP_TEST;
      } 
      else{
        console.log("LOCAL ENVIRONMENT");
        fileName = process.env.VUE_APP_CATALOG_APP_LOCAL;
      }
      if(type === 'tnc'){
        this.termsAndPrivacyheight = 22000
        fileName += `legal/Terms_and_conditions_202407_` //if the file is intact we can use ${this.getLanguage}
      }
      if(type === 'pp'){
        this.termsAndPrivacyheight = 7000
        fileName += `legal/Privacy_Policy_202407_`
      }
      switch(this.getLanguage){
        case 'id':
          this.fileName = fileName + 'id.html';//&embedded=true" style="width:600px; height:500px;';
          break;
        case 'en':
          this.fileName = fileName + 'en.html'
          // this.fileName = fileName + 'en.pdf';//&embedded=true" style="width:600px; height:500px;';
          break;
        case 'it':
          this.fileName = fileName + 'it.html';//&embedded=true" style="width:600px; height:500px;';
          break;
        default:
          this.fileName = fileName + 'en.html';//&embedded=true" style="width:600px; height:500px;';
          break;
      }
      console.log(this.fileName);
      this.dialogFilePreview = true;
    },
    signOut() {
      this.$store.dispatch('logout').then(() => {
        // const firstScrollTo = scroller();
        // console.log('Clear userPredictionsArr Array')
        // this.$store.dispatch('clearUserPredictions')
        setTimeout(() => {
          this.$router.push('/');
          window.location.reload();
          // firstScrollTo('#hometoolbar');
        }, 1000);
      });
    }
  },
};
</script>
<style>
html {
  overscroll-behavior: none;
}

.noscroll{
  position: fixed;
  overflow-x: hidden;
  overflow-y: hidden;
}
</style>
