<template>
  <v-card :dark="darkMode"
          class="mx-md-2 my-md-4 my-0 mx-1 fill-height card-outter" 
          elevation="4"  
          @click.prevent="cardClicked" 
          style="border: solid 0,4px gray; max-width: 100%; overflow-x: hidden;">
  <!-- <v-img
    :src="itemData.image"
    :lazy-src="itemData.image"
    aspect-ratio="1"
    :max-width="isMobileDevice ? (windowWidth - 15) : '100%'"
  ></v-img> -->
  <v-img
    :src="itemData.image"
    eager
    :aspect-ratio="itemData?.imageAspectRatio"
  ></v-img>
  <!-- <v-row
    v-else
    class="mb-4"
    justify="center" 
    align="center" 
    :style="`background-image: url(${itemData.image}); background-size: contain; height: ${windowWidth}px; width: auto`" >
  </v-row> -->
  <!-- <div class="d-flex flex-column" style="height: 0%"> -->
  <v-card-subtitle class="pb-0 d-flex">
      <v-chip outlined class="my-0" :x-small="!isMobileDevice" :small="isMobileDevice" :ripple="false" color="secondary">
        {{ ![null,undefined,''].includes(category) ? category.lang[getLanguage].toUpperCase() : 'UKNOWN' }}
      </v-chip>
      <v-spacer></v-spacer>

      <v-icon v-if="itemData.rating === 1" color="#C40621">mdi-star-box-outline</v-icon>
    </v-card-subtitle>
  <v-card-title v-if="!isMobileDevice" :class="isMobileDevice ? 'mt-2 mb-4 mb-md-2 font-weight-bold pl-2 pr-2' : 'mt-2 mb-4 mb-md-8 font-weight-bold pl-2 pr-2'" 
    :style="isMobileDevice ? 
    'line-height: 1.8;font-size:1.6rem; align-items: flex-start; word-break: break-word;' : 
    'line-height: 1.3;font-size:0.9rem; align-items: flex-start; word-break: break-word;'">
    {{ itemData.title }}
  </v-card-title>
  <v-card-title v-if="isMobileDevice" 
    style="word-break: break-word;"
    class="mt-2 mb-4 mb-md-2 font-weight-bold pl-2 pr-2 text-h4">
    {{ itemData.title }}
  </v-card-title>
  <v-card-text class="pb-md-4 pb-2 pl-2 pr-1" v-if="isMobileDevice">
    <div class="pb-md-4 pb-1 font-weight-bold text-h5">
      <span class="d-flex justify-start"> 
        <!--<v-icon v-if="isMobileDevice" color="#C40621" class="mr-md-2 mr-1 mb-0" left >mdi-circle-multiple-outline</v-icon> -->
        <span class="mr-1" :style="isMobileDevice ? 'font-size:0.9rem!important;' : drawer ? 'font-size:0.7rem!important;line-height: 1' : 'font-size:0.8rem!important;line-height: 1'">
          {{ itemData.price }}
        </span>
        <span class="" :style="isMobileDevice ? 'font-size:1rem!important;': drawer ? 'font-size:0.7rem!important;line-height: 1' : 'font-size:0.8rem!important;line-height: 1'">
          {{ lang[getLanguage].MY_ACCOUNT_MOLA_POINTS }}
        </span>
      </span>
    </div>
    <!-- <div class="pt-md-0 pt-2" :style="isMobileDevice ? 'font-size:1rem !important':'font-size:0.7rem!important;'">{{ lang[getLanguage].AVAILABLE }}: {{ Number.parseInt(itemData.stock.toString()) - Number.parseInt(itemData.redeemed.toString()) }}</div> -->
  </v-card-text>
  <div class="card-actions">
    <v-card-text class="pb-md-4 pb-2 pl-2 pr-1 pt-2" v-if="!isMobileDevice">
    <div class="pb-md-4 pb-1 font-weight-bold text-h5">
      <span class="d-flex justify-start"> 
        <!--<v-icon v-if="isMobileDevice" color="#C40621" class="mr-md-2 mr-1 mb-0" left >mdi-circle-multiple-outline</v-icon> -->
        <span class="mr-1" :style="isMobileDevice ? 'font-size:0.8rem!important;' : drawer ? 'font-size:0.7rem!important;line-height: 1' : 'font-size:0.8rem!important;line-height: 1'">
          {{ itemData.price }}
        </span>
        <span class="" :style="isMobileDevice ? 'font-size:0.8rem!important;': drawer ? 'font-size:0.7rem!important;line-height: 1' : 'font-size:0.8rem!important;line-height: 1'">
          {{ lang[getLanguage].MY_ACCOUNT_MOLA_POINTS }}
        </span>
      </span>
    </div>
    <!-- <div class="pt-md-0 pt-2" :style="isMobileDevice ? 'font-size:1rem!important: 1':'font-size:0.7rem!important;'">{{ lang[getLanguage].AVAILABLE }}: {{ Number.parseInt(itemData.stock.toString()) - Number.parseInt(itemData.redeemed.toString()) }}</div> -->
  </v-card-text>
  <v-card-actions class="flex-column pb-2 pt-auto">
      <!-- <v-btn block color="primary" outlined class="mb-4" @click.stop="addToCart">
          Add to Cart
      </v-btn> -->
      <!-- <v-btn block 
        v-if="[null, undefined, false].includes(itemData.isHideRedeem) && !itemData.isReward"
             color="primary" 
             :small="!isMobileDevice" class="mx-0" 
             :style="drawer ? 'border-radius: 5px;font-size: 0.7rem;' : 'border-radius: 5px;font-size: 0.8rem;'" 
             @click.stop="redeemNow"
        >
        {{ lang[getLanguage].REDEEM_NOW }}
      </v-btn> -->
      <v-btn
        v-if="itemData?.isHideRedeem && !itemData?.isReward"
          color="secondary"
          outlined
          block
          :small="!isMobileDevice"
          @click.stop=""
          :style="drawer ? 'border-radius: 5px;font-size: 0.7rem;' : 'border-radius: 5px;font-size: 0.8rem;'" 
          :class="`${itemData?.comingSoonDate ? 'mb-2' : ''} ma-0`"
        >
        {{ lang[getLanguage].COMING_SOON }}
      </v-btn>
      <div v-if="itemData?.isHideRedeem && !itemData?.isReward" class="blue--text text-center">
        <v-chip x-small color="secondary" outlined v-if="itemData?.comingSoonDate">
          {{ formatDate(itemData?.comingSoonDate) }}
        </v-chip>
      </div>
      <v-btn
        v-if="!itemData?.isHideRedeem && itemData?.isReward"
          color="secondary"
          outlined
          block
          :small="!isMobileDevice"
          @click.stop="cardClicked"
          :style="drawer ? 'border-radius: 5px;font-size: 0.7rem;' : 'border-radius: 5px;font-size: 0.8rem;'" 
          :class="`${itemData?.comingSoonDate ? 'mb-2' : ''} ma-0`"
        >
        {{ lang[getLanguage].REWARD }}
      </v-btn>
  </v-card-actions>
</div>
  <!-- </div> -->
</v-card>
</template>

<script>
export default {
  props: {
    itemData: {
      type: Object,
      required: true,
    },
    darkMode: {
      type: Boolean,
      required: true,
    },
    isMobileDevice: Boolean,
    drawer: Boolean,
    windowWidth: Number
  },
  data() {
    return {
      flipped: false,
      category: '',
    }
  },
  computed:{
    lang(){
      return this.$store.state.lang;
    },
    getLanguage(){
      return this.$store.state.current_language
    },
    categories(){
      return this.$store.state.catalog.categories;
    },
  },
  methods: {
    cardClicked() {
      this.$emit("choose", this.itemData)
    },
    addToCart() {
      this.$emit("cart", this.itemData)
    },
    redeemNow() {
      this.$emit("redeem", this.itemData)
    },
    formatDate(unformattedDate) {
      const date = new Date(unformattedDate);
      const options = { day: '2-digit', month: 'long', year: 'numeric' };
      const formattedDate = date.toLocaleDateString('id-ID', options);
      return formattedDate;
    }
  },
  created(){
    this.category = this.categories.filter(item => item.name === this.itemData.category)[0];
    // console.log(this.category)
  }
};
</script>

<style scoped>
/* The flip card container - set the width and height to whatever you want. We have added the border property to demonstrate that the flip itself goes out of the box on hover (remove perspective if you don't want the 3D effect */
.flip-card {
perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-card-inner {
position: relative;
width: 100%;
height: 100%;
transition: transform 0.8s;
transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-card-inner {
transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front, .flip-card-back {
position: absolute;
width: 100%;
-webkit-backface-visibility: hidden; /* Safari */
backface-visibility: hidden;
}

/* Style the back side */
.flip-card-back {
width: 100%;
transform: rotateY(180deg);
}

.card-outter {
  padding-bottom: 100px;
}
.card-actions {
  position: absolute;
  width: 100%;
  bottom: 0;
}
</style>